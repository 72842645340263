<template>
  <div class="page-titlebar-container">
    <slot name="above" />
    <div class="shadow-default q-mb-md">
      <div class="page-titlebar">
        <div>
          <slot name="top" />
        </div>
        <div
          class="row justify-between"
          style="min-height: 34px"
          :class="{
            'items-center': !$slots.bottom,
            'items-end': $slots.bottom
          }"
        >
          <div v-if="title.length > 0" class="flex items-center">
            <q-icon
              v-if="titleIcon.length > 0"
              :name="titleIcon"
              class="q-mr-md"
              size="sm"
            />
            <div>
              <h5>{{ title }}</h5>
              <p
                v-if="subTitle.length > 0"
                v-html="subTitle"
                class="text-font-secondary"
              ></p>
              <p v-else class="text-font-secondary">
                <slot name="sub-title" />
              </p>
            </div>
          </div>

          <slot name="title" />

          <div class="q-gutter-x-md">
            <slot name="actions" />
          </div>
        </div>
      </div>
      <div :class="{ 'q-px-lg q-py-sm q-card--bordered': $slots.bottom }">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitlebar",
  props: {
    title: {
      type: String,
      default: ""
    },
    titleIcon: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.page-titlebar-container {
  background: #fff;
  margin: (-$space-xl) (-$space-lg) $space-lg;

  position: sticky;
  top: $the-titlebar-height;
  z-index: 2;

  .page-titlebar {
    padding: $space-md $space-lg;
    min-height: 66px;

    .page-titlebar-bottom {
      border-top: 1px solid color(border);
    }
  }
}
</style>
